<!-- resources/js/MyComponent.vue -->
<template>
    <div class="max-w-[1430px] mx-auto w-full">
        <div class="bg-white block w-full md:flex relative mx-auto">
            <div class="relative h-full max-w-[100%]" style="width: 460px;">
                <div class="w-full h-full overflow-x-hidden">
                    <div class="relative h-full w-full">
                        <div class="py-4 px-4 md:px-4 text-gray-700 pb-[50px] gap-x-2 sm:block border-r-2 border-gray-200">
                            <div class="text-md font-bold pb-2 border-b border-[#DEE8F9]"><i class="w-4 h-4 bg-contain bg-center bg-[url(/images/icons/essay-writer.webp)] inline-flex"></i><span class="text-base ml-1">Ai Essay Writer</span></div>
                            <label class="flex text-sm font-semibold mt-3">Write Essay Topic or Title here?</label>
                            <textarea rows="6" @focus="clearError('rawtext')" v-model="rawtext" :class="{ 'border-red-500': rawTextErrorMessage }" class="w-full border-gray-200 rounded-md mt-1" ></textarea>
                            <div v-if="rawTextErrorMessage" class="text-xs text-red-500"> {{ rawTextErrorMessage }} </div>

                            <p class="text-xs text-right text-gray-700" :class="rawtext.length > 250 ? 'text-red-400':''">{{rawtext.length}}/250</p>
                            <label for="educationLevel" class="text-sm font-semibold mt-3">Education level (optional)</label>
                            <select  id="educationLevel" v-model="education_level" class="mt-1 shadow appearance-none border-gray-200 rounded-md rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Education level">
                                <option value="school">School</option>
                                <option value="college">College</option>
                                <option value="graduate">Graduate</option>
                            </select>
                            <label for="language" class="text-sm font-semibold mt-3">Language</label>
                            <select id="language" name="language" v-model="lang" class="mt-1 shadow appearance-none border-gray-200 rounded-md rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Select Language">
                                <option value="english">English</option>
                            </select>
                            <div class="w-full py-2">
                                <div id="cf-container"></div>
                            </div>
                            <button @click="doParaphrase()" :disabled="loading ||  token == null" class="flex flex-wrap mt-4 ml-0 bg-[#8730cb] hover:bg-[#610aa5] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline gap-x-2" type="button">
                                <svg v-if="loading" class="animate-spin  h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Generate Essay
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-1 h-auto bg-white border-l">
                <QuillEditor theme="snow" v-model:content="editortext" contentType="text" toolbar="minimal" class="h-auto" />
                <ActionButtonsComponent :text-to-copy="editortext" :editortext="editortext"/>
            </div>
            <ErrorPopupComponent v-if="showErrorPopup" :rawtext="rawtext" @close="showErrorPopup = false" @validation-error="handleValidationError" />
        </div>
        <div class="mx-auto w-full mt-4 md:mt-5">
            <history-component ref="historyComponent"></history-component>
        </div>
    </div>

</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import { ref } from "vue";
import axios from "axios";
import ActionButtonsComponent from '@/Components/ActionButtonsComponent.vue';
import ErrorPopupComponent from '@/Components/ErrorPopupComponent.vue';
import HistoryComponent from '@/Components/HistoryComponent.vue';

export default {
    components: {
        QuillEditor,
        ActionButtonsComponent,
        ErrorPopupComponent,
        HistoryComponent
    },
    props: {
        translations: Object,
    },
    data() {
        return {
            rawtext: '',
            lang:'english',
            education_level: null,
            loading:false,
            creativity: 1,
            sitekey:'0x4AAAAAAAQWyAxhVrxfHtpm',
            token: null,
            editortext:null,
            csrf:null,
            showErrorPopup: false, // show popup on click
            rawTextErrorMessage: '',

        };
    },
    mounted(){
        this.renderCf();
        this.csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    },
    beforeMount() {
        this.cfInit();
    },
    methods: {
        cfInit() {
            const e = document.createElement("script"), t = "https://challenges.cloudflare.com/turnstile/v0/api.js";
            e.src = `${t}?onload=onloadTurnstileCallback&render=explicit`, e.async = !0, e.defer = !0, document.head.appendChild(e);
        },
        renderCf() {
            let _this = this;
            window.onloadTurnstileCallback = function () {
                turnstile.render('#cf-container', {
                    sitekey: _this.sitekey,
                    callback: function (token) {
                        _this.verify(token);
                    },
                });
            };
        },
        verify(token) {
            this.token = token;
        },
        doParaphrase() {
            this.loading = true;
            this.rawTextErrorMessage = '';
            fetch("/tool/essayWriting", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'stream-data': true, // Assuming the server supports streaming
                    'X-CSRF-TOKEN': this.csrf
                },
                body: JSON.stringify({
                    capcha_token: this.token,
                    rawtext: this.rawtext,
                    education_level: this.education_level,
                    lang: this.lang,
                    mode: 'creative',
                })
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch data');
                    }

                    const reader = response.body.getReader();
                    const decoder = new TextDecoder('utf-8');
                    let content = '';

                    const processText = ({done, value}) => {
                        if (done) {
                            // Stream ended
                            this.editortext = content;
                            this.loading = false;
                            turnstile.reset('#cf-container');
                            return;
                        }

                        content += decoder.decode(value, {stream: true});
                        this.editortext = content; // Update text incrementally
                        // Continue reading next chunk
                        reader.read().then(processText).catch(error => {
                            console.error('Failed to read the stream', error);
                            let message = "Something went wrong. Please try again.";
                            alert(message);
                            this.showErrorPopup = true; // show popup on click
                            this.loading = false;
                            turnstile.reset('#cf-container');
                        });
                    };

                    // Start processing the stream
                    reader.read().then(processText).catch(error => {
                        console.error('Failed to read the stream', error);
                        let message = "Something went wrong. Please try again.";
                        alert(message);
                        this.showErrorPopup = true; // show popup on click
                        this.loading = false;
                        turnstile.reset('#cf-container');
                    });
                    this.$refs.historyComponent.setLoadData({
                        "tool": "AI Essay Writer",
                        "title": this.rawtext.substring(0, 100)+"...",
                        "context": this.editortext,
                        "slug": "ai-essay-writer"
                    });
                })
                .catch(error => {
                    this.loading = false;
                    turnstile.reset('#cf-container');
                    this.showErrorPopup = true; // show popup on click

                });

        },
        handleValidationError({errorType, message}) {
            if (errorType === 'rawtext') {
                this.rawTextErrorMessage = message;
            }
            const allErrorsCleared = !this.rawTextErrorMessage;
            if (allErrorsCleared) {
                this.showErrorPopup = false; // Hide popup if no errors
            }else {
                this.showErrorPopup = true; // Show popup if there are errors
            }
        },
        clearError(type) {
            if (type === 'rawtext') {
                this.rawTextErrorMessage = '';
            }
        },
    }
};

</script>
<style>
.ql-editor {
    height: 100% !important;
}
.ql-container {
    height: auto !important;
}
.ql-container.ql-snow {
    border: none!important;
}
.ql-toolbar.ql-snow{
    border: none!important;
    border-bottom: 1px solid rgb(229 231 235);
}
.highlight {
    background-color: yellow;
}
</style>

