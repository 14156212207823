import './bootstrap';
import '../css/app.css';

import { createApp } from 'vue'; // Import Vue 3's createApp function

import HomePageComponent from './Components/HomePageComponent.vue';
import ParaphrasingToolComponent from './Components/ParaphrasingToolComponent.vue';
import ParagraphGeneratorComponent from './Components/ParagraphGeneratorComponent.vue';
import AiStoryGeneratorComponent from './Components/AiStoryGeneratorComponent.vue';
import PoemGeneratorComponent from './Components/PoemGeneratorComponent.vue';
import AiEssayGeneratorComponent from './Components/AiEssayGeneratorComponent.vue';
import ThesisStatementGeneratorComponent from './Components/ThesisStatementGeneratorComponent.vue';
import BookTitleGeneratorComponent from './Components/BookTitleGeneratorComponent.vue';
import AiTextGeneratorComponent from './Components/AiTextGeneratorComponent.vue';
import AiScriptGeneratorComponent from './Components/AiScriptGeneratorComponent.vue';
import PunctuationComponent from './Components/PunctuationComponent.vue';
import AiFaqGeneratorComponent from './Components/AiFaqGeneratorComponent.vue';
import AiEmailWriterComponent from './Components/AiEmailWriterComponent.vue';
import AiWritingAssistantComponent from './Components/AiWritingAssistantComponent.vue';
import AiPaperWriterComponent from './Components/AiPaperWriterComponent.vue';
import AiBlogWriterComponent from './Components/AiBlogWriterComponent.vue';
import MetaDescriptionComponent from './Components/MetaDescriptionComponent.vue';
import ProductDescriptionComponent from './Components/ProductDescriptionComponent.vue';
import AiRhymeGeneratorComponent from './Components/AiRhymeGeneratorComponent.vue';
import StoryCharacterComponent from './Components/StoryCharacterComponent.vue';
import LinkedSumeryComponent from './Components/LinkedSumeryComponent.vue';
import QuoraAnswerComponent from './Components/QuoraAnswerComponent.vue';
import CoverLetterComponent from './Components/CoverLetterComponent.vue';
import LetterWriterComponent from './Components/LetterWriterComponent.vue';
import AiLyricesComponent from './Components/AiLyricesComponent.vue';
import AiReviewComponent from './Components/AiReviewComponent.vue';
import  WordGeneratorComponent from './Components/WordGeneratorComponent.vue';
import  AiStoryNameComponent from './Components/AiStoryNameComponent.vue';
import  SentenceCounterComponent from './Components/SentenceCounterComponent.vue';
import  ChatgptAlternativeComponent from './Components/ChatgptAlternativeComponent.vue';
import  IntroductionGeneratorComponent from './Components/IntroductionGeneratorComponent.vue';
import  AiCopyWriterComponent from './Components/AiCopyWriterComponent.vue';
import ActionButtonsComponent from './Components/ActionButtonsComponent.vue';
import  AiQuoteComponent from './Components/AiQuoteComponent.vue';
import  WritingPromptComponent from './Components/WritingPromptComponent.vue';
import  AiSummarizerComponent from './Components/AiSummarizerComponent.vue';
import  charGreentextStoryComponent from './Components/charGreentextStoryComponent.vue';
import  OutlineGeneratorComponent from './Components/OutlineGeneratorComponent.vue';
import  AiBackStoryComponent from './Components/AiBackStoryComponent.vue';
import  TopicSentenceComponent from './Components/TopicSentenceComponent.vue';
import  PlotComponent from './Components/PlotComponent.vue';
import  HaikuPoemComponent from './Components/HaikuPoemComponent.vue';
import  AiCharacterComponent from './Components/AiCharacterComponent.vue';
import  SloganComponent from './Components/SloganComponent.vue';
import  HookSentenceComponent from './Components/HookSentenceComponent.vue';
import  LoveLetterComponent from './Components/LoveLetterComponent.vue';
import  AiComicMakerComponent from './Components/AiComicMakerComponent.vue';
import  AiToolsComponent from './Components/AiToolsComponent.vue';
import  ErrorPopupComponent from './Components/ErrorPopupComponent.vue';
import  LoginButtonComponent from './Components/LoginButtonComponent.vue';
import  LogoutButtonComponent from './Components/LogoutButtonComponent.vue';
import  HistoryComponent from './Components/HistoryComponent.vue';
import  NicknameComponent from './Components/NicknameComponent.vue';

/**
 * AI Writer components
 */
import AiWriterComponent from './Components/aiWriter/AiWriterComponent.vue';
import AiWriterProjectComponent from './Components/aiWriter/ProjectComponent.vue';
//import BlogToInfographicComponent from './Components/BlogToInfographicComponent.vue';
import ClickOutside from './ClickOutsideDirective'; // Adjust the import path according to your project structure

/* end */
const app = createApp({
    components: {
        //BlogToInfographicComponent,
        HomePageComponent,
        ParaphrasingToolComponent,
        ParagraphGeneratorComponent,
        AiStoryGeneratorComponent,
        PoemGeneratorComponent,
        AiEssayGeneratorComponent,
        ThesisStatementGeneratorComponent,
        BookTitleGeneratorComponent,
        AiTextGeneratorComponent,
        AiScriptGeneratorComponent,
        PunctuationComponent,
        AiFaqGeneratorComponent,
        AiEmailWriterComponent,
        AiWritingAssistantComponent,
        AiWriterProjectComponent,
        AiPaperWriterComponent,
        AiBlogWriterComponent,
        MetaDescriptionComponent,
        ProductDescriptionComponent,
        AiRhymeGeneratorComponent,
        StoryCharacterComponent,
        LinkedSumeryComponent,
        QuoraAnswerComponent,
        CoverLetterComponent,
        LetterWriterComponent,
        AiLyricesComponent,
        AiReviewComponent,
        WordGeneratorComponent,
        AiStoryNameComponent,
        SentenceCounterComponent,
        ChatgptAlternativeComponent,
        IntroductionGeneratorComponent,
        AiCopyWriterComponent,
        AiWriterComponent,
        ActionButtonsComponent,
        AiQuoteComponent,
        WritingPromptComponent,
        AiSummarizerComponent,
        charGreentextStoryComponent,
        OutlineGeneratorComponent,
        AiBackStoryComponent,
        TopicSentenceComponent,
        PlotComponent,
        HaikuPoemComponent,
        AiCharacterComponent,
        SloganComponent,
        HookSentenceComponent,
        LoveLetterComponent,
        AiComicMakerComponent,
        AiToolsComponent,
        ErrorPopupComponent,
        LoginButtonComponent,
        LogoutButtonComponent,
        HistoryComponent,
        NicknameComponent,
    },
    data(){
        return{
            searchKeyword:''
        };
    }
});
app.directive('click-outside', ClickOutside);
app.mount('#app');
window.addEventListener("load", function(){
    let images=[...document.getElementsByClassName("loadimg")];
    function onIntersection(e){e.forEach(e=>{e.isIntersecting&&(observer.unobserve(e.target),e.target.src=e.target.dataset.src,e.target.alt=e.target.dataset.src,e.target.onload=()=>e.target.classList.add("lazyload"))})}let observer=new IntersectionObserver(onIntersection);images.forEach(e=>observer.observe(e));
});
