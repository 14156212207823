<template>
    <div v-if="historyList.length" class="bg-white max-w-full flex flex-wrap mx-auto my-4 border border-gray-200">
        <div class="w-full p-4"><p class="font-semibold text-sm">Activity History ({{historyList.length}}):</p></div>
        <div v-for="(history,index ) in historyList" class="relative border-b border-gray-200 w-full flex flex-wrap px-4 py-3">
            <div class="w-full flex cursor-pointer text-left" @click="selected !== index ? selected = index : selected = null">
                <div class="w-full flex items-center justify-between text-sm"> <span>
                <button @click="removeHist(index)" title="Remove" type="button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 1024 1024" class="icon" version="1.1"><path d="M960 160h-291.2a160 160 0 0 0-313.6 0H64a32 32 0 0 0 0 64h896a32 32 0 0 0 0-64zM512 96a96 96 0 0 1 90.24 64h-180.48A96 96 0 0 1 512 96zM844.16 290.56a32 32 0 0 0-34.88 6.72A32 32 0 0 0 800 320a32 32 0 1 0 64 0 33.6 33.6 0 0 0-9.28-22.72 32 32 0 0 0-10.56-6.72zM832 416a32 32 0 0 0-32 32v96a32 32 0 0 0 64 0v-96a32 32 0 0 0-32-32zM832 640a32 32 0 0 0-32 32v224a32 32 0 0 1-32 32H256a32 32 0 0 1-32-32V320a32 32 0 0 0-64 0v576a96 96 0 0 0 96 96h512a96 96 0 0 0 96-96v-224a32 32 0 0 0-32-32z" fill="#000000"/><path d="M384 768V352a32 32 0 0 0-64 0v416a32 32 0 0 0 64 0zM544 768V352a32 32 0 0 0-64 0v416a32 32 0 0 0 64 0zM704 768V352a32 32 0 0 0-64 0v416a32 32 0 0 0 64 0z" fill="#000000"/></svg>
                </button> {{index + 1}}. {{history.title}} </span>
                    <svg :class="{'transform rotate-180' : selected == index}" class="w-4 h-4 text-gray-500" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M19 9l-7 7-7-7"></path></svg>
                </div>
            </div>
            <div v-if="selected == index" class="block bg-white mt-4 transition-all duration-700 ">
                <div class="absolute top-0 right-0 mt-16 mr-8 flex" >
                    <div class="mt-1">
                        <button @click="openPopup(history.context)" class="bg-white hover:shadow-inner px-3 py-2 text-sm font-medium border border-gray-200 rounded-s-lg focus:z-10 focus:text-[#FFAC48]">
                            <img :src="'/images/download.svg'" alt="download.svg" width="15px" height="15px" >
                        </button>
                    </div>
                    <div class="mt-1">
                        <button @click="copyText(history.context)" class="hover:shadow-inner px-3 py-2 text-sm font-medium bg-white border border-gray-200 focus:z-10 focus:text-[#FFAC48]">
                            <img :src="'/images/copy.svg'" alt="copy.svg" width="15px" height="15px" >
                        </button>
                    </div>
                    <div class="mt-1">
                        <button v-if="!isSpeaking" @click="handleClick(history.context)" class="hover:shadow-inner px-3 py-2 text-sm font-medium bg-white border border-gray-200 rounded-e-lg focus:z-10 focus:text-[#FFAC48]">
                            <img :src="'/images/play.svg'" alt="play.svg" width="15px" height="15px" >
                        </button>
                        <button v-if="isSpeaking" @click="onStopClick" class="hover:shadow-inner px-3 py-2 text-sm font-medium bg-white border border-gray-200 rounded-e-lg focus:z-10 focus:text-[#FFAC48]">
                            <img :src="'/images/pause.svg'" alt="pause.svg" width="15px" height="15px" >
                        </button>
                    </div>
                </div>
                <div class="block bg-gray-100 rounded-md transition-all duration-700 p-4 border-2 border-dash border-gray-200" v-html="history.context"></div>
            </div>
        </div>

        <div v-if="showPopup" class="popup-overlay">
            <div class="popup-content bg-white md:w-[450px] md:h-[300px] rounded-md shadow-2xl flex items-center my-auto relative">
                <div class="mx-auto align-center border rounded-lg px-4 py-4 flex flex-col justify-center items-center hover:bg-gray-200">
                    <img :src="'/images/text_file.svg'" alt="text_file.svg" width="50px" height="50px" >
                    <button @click="downloadAsTextFile" class="my-4 font-bold text-sm">Download as Text File</button>
                </div>
                <div class="mx-auto align-center border rounded-lg px-4 py-4 flex flex-col justify-center items-center hover:bg-gray-200">
                    <img :src="'/images/doc_files.svg'" alt="doc_file.svg" width="50px" height="50px" >
                    <button @click="downloadAsDocFile" class="my-4 font-bold text-sm">Download as DOC File</button>
                </div>
                <button @click="closePopup" class="absolute top-1 right-1 inline-flex items-center my-auto z-50 px-2 py-2 rounded bg-[#A563D8] hover:bg-[#734597]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#0F1729"/>
                    </svg>
                </button>
            </div>
        </div>
    </div>

</template>
<script >

export default {
    components:{
    },
    props: {
    },
    data() {
        return {
            historyList: [],
            selected: null,
            isSpeaking: false, // Track whether speech is active
            showPopup: false,
            currentUtterance: null,
            currentBoundary: null,
            textToCopy:'',
            editortext:'',
        };
    },
    mounted(){
        //this.setLoadData();
        this.getLoadData();
    },
    methods: {
        removeHist(index){
            var existingEntries = JSON.parse(localStorage.getItem("entityHistoryList"));
            existingEntries.splice(index, 1);
            localStorage.setItem("entityHistoryList", JSON.stringify(existingEntries));
            this.getLoadData();
        },
        setLoadData(entry){
            var existingEntries = JSON.parse(localStorage.getItem("entityHistoryList"));
            if(existingEntries == null) existingEntries = [];
            existingEntries.push(entry);
            localStorage.setItem("entityHistoryList", JSON.stringify(existingEntries));
        },
        getLoadData(){
            this.historyList = JSON.parse(localStorage.getItem('entityHistoryList'));
            console.log(this.historyList)
        },
        async copyText(textToCopy) {
            this.textToCopy = textToCopy;
            try {
                await navigator.clipboard.writeText(this.textToCopy);
                alert('Text copy successfully')
                console.log('Text copied to clipboard');
            } catch (err) {
                console.error('Failed to copy text: ', err);
            }
        },

        openPopup(textToCopy) {
            this.showPopup = true;
            this.textToCopy = textToCopy
        },
        handleClick(textToCopy) {
            this.textToCopy = textToCopy;

            if (this.isSpeaking) {
                this.stopSpeech(); // Stop any current speech before starting new
            }

            const text = this.textToCopy;
            if (!text) {
                console.error('No text to speak');
                return;
            }

            let start = 0;

            if (this.currentBoundary != null) {
                start = this.currentBoundary;
            }

            const utterance = new SpeechSynthesisUtterance(text.substring(start));

            utterance.onboundary = (event) => {
                this.currentBoundary = event.charIndex + start;
            };

            utterance.onend = () => {
                console.log('Speech has finished.');
                this.isSpeaking = false;
                this.currentBoundary = null;
            };

            this.currentUtterance = utterance;
            window.speechSynthesis.speak(utterance);
            this.isSpeaking = true;
        },


        onStopClick() {
            this.stopSpeech();
        },
        stopSpeech() {
            if (this.currentUtterance) {
                window.speechSynthesis.cancel();
                this.currentUtterance = null;
                this.isSpeaking = false;
            }
        },

        closePopup() {
            this.showPopup = false;
        },

        downloadAsTextFile() {
            const blob = new Blob([this.textToCopy], { type: 'text/plain' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'content.txt';
            a.click();
            URL.revokeObjectURL(url);
            this.closePopup();
        },

        downloadAsDocFile() {
            const content = `
            <html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:w="urn:schemas-microsoft-com:office:word" xmlns="http://www.w3.org/TR/REC-html40">
            <head><meta charset="utf-8"><title>Document</title></head><body>
            <div>${this.textToCopy}</div>
            </body></html>
          `;

            const blob = new Blob(['\ufeff', content], {
                type: 'application/msword'
            });

            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'content.doc';
            a.click();
            URL.revokeObjectURL(url);
            this.closePopup();
        },
    }
}
</script>
