<template>
    <button  v-if="!isLoggedIn" @click="openPopup" class="font-semibold bg-violet-600 text-white p-2 rounded-md">
        <slot>
            Try For Free
        </slot>
    </button>

    <div v-if="isPopupVisible" class="popup-overlay">
        <div class="popup-content bg-white md:w-[650px] md:h-auto rounded-md shadow-2xl my-auto px-2 py-5 relative">
            <h1 class="mb-4 text-4xl tracking-tight font-bold text-center text-gray-900 dark:text-white">Login</h1>
            <p class="mb-8 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
                Login to be more secure and personalized experience
            </p>
            <form @submit.prevent="submitForm" class="md:w-[500px] mx-auto">
                <input type="hidden" name="_token" :value="csrfToken" />
                <div>
                    <label for="email" class="block my-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Email Address
                    </label>
                    <input
                        id="email"
                        name="email"
                        v-model="form.email"
                        class="border-gray-300 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-300 focus:border-indigo-500 dark:focus:border-indigo-600 focus:ring-indigo-500 dark:focus:ring-indigo-600 rounded-md shadow-sm block mt-1 w-full"
                        type="email"
                        required
                        autofocus
                        autocomplete="username"
                    />
                    <span v-if="errors.email" class="mt-2 text-red-600">{{ errors.email[0] }}</span>
                </div>

                <div class="mt-4">
                    <label for="password" class="block my-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Password
                    </label>
                    <input
                        id="password"
                        name="password"
                        v-model="form.password"
                        class="border-gray-300 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-300 focus:border-indigo-500 dark:focus:border-indigo-600 focus:ring-indigo-500 dark:focus:ring-indigo-600 rounded-md shadow-sm block mt-1 w-full"
                        type="password"
                        required
                        autocomplete="current-password"
                    />
                    <span v-if="errors.password" class="mt-2 text-red-600">{{ errors.password[0] }}</span>
                </div>

                <!-- Remember Me -->
                <div class="block mt-4">
                    <label for="remember_me" class="w-full flex flex-wrap my-2 gap-2 items-center">
                        <input
                            id="remember_me"
                            name="remember"
                            type="checkbox"
                            v-model="form.remember"
                            class="rounded border-gray-300 text-indigo-600 shadow-sm focus:ring-indigo-500"
                        />
                        <span class="ml-2 text-sm text-gray-600">Remember me</span>
                    </label>
                </div>

                <!-- recaptcha -->
                <div class="cf-turnstile" data-sitekey="0x4AAAAAAAQWyAxhVrxfHtpm"></div>
                <x-input-error :messages="errors['cf-turnstile-response']" class="mt-2" />
                <!-- recaptcha -->
                <div class="w-full">
                    <button
                        type="submit"
                        class="w-full py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-[#8B5CF6] hover:bg-[#8B5CF6] focus:ring-4 focus:outline-none"
                    >
                        Login
                    </button>
                    <div v-if="hasPasswordRequestRoute" class="my-2 text-center">
                        <a class="btn btn-link" :href="passwordRequestRoute">
                            Forgot your password?
                        </a>
                    </div>
                </div>
                <div>
                    <div class="my-2 text-center">OR</div>
                    <a
                        href="/google_login"
                        class="w-full flex flex-wrap justify-center items-center cursor-pointer gap-2 border border-gray-300 py-3 px-5 text-sm font-medium text-center text-black rounded-lg bg-white focus:ring-4 focus:outline-none focus:ring-blue-300"
                    >
                        <svg viewBox="-3 0 262 262" class="w-4 h-4"  fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027" fill="#4285F4"></path><path d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1" fill="#34A853"></path><path d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782" fill="#FBBC05"></path><path d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251" fill="#EB4335"></path></g></svg>
                        Login with Google
                    </a>
                </div>
                <div class="flex justify-center my-3">
                    Don't have an account <u class="mx-1 text-[#8B5CF6]"><a href="/register">Register here</a></u>
                </div>
            </form>
            <button @click="closePopup" class="absolute top-1 right-1 inline-flex items-center my-auto z-50 px-2 py-2 rounded bg-[#A563D8] hover:bg-[#734597]">
                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#0F1729"/>
                </svg>
            </button>
        </div>
    </div>

    <div v-if="isLoggedIn" class="items-center gap-4 flex relative">
        <div class="relative text-sm z-[99]">
            <div
                class="py-1 px-1 rounded flex justify-between items-center gap-1 hover:cursor-pointer"
                @click="toggleDropdown"
            >
                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" viewBox="0 0 24 24" fill="none">
                    <path opacity="0.1" fill-rule="evenodd" clip-rule="evenodd" d="M3 12C3 4.5885 4.5885 3 12 3C19.4115 3 21 4.5885 21 12C21 16.3106 20.4627 18.6515 18.5549 19.8557L18.2395 18.878C17.9043 17.6699 17.2931 16.8681 16.262 16.3834C15.2532 15.9092 13.8644 15.75 12 15.75C10.134 15.75 8.74481 15.922 7.73554 16.4097C6.70593 16.9073 6.09582 17.7207 5.7608 18.927L5.45019 19.8589C3.53829 18.6556 3 16.3144 3 12ZM8.75 10C8.75 8.20507 10.2051 6.75 12 6.75C13.7949 6.75 15.25 8.20507 15.25 10C15.25 11.7949 13.7949 13.25 12 13.25C10.2051 13.25 8.75 11.7949 8.75 10Z" fill="#323232"/>
                    <path d="M3 12C3 4.5885 4.5885 3 12 3C19.4115 3 21 4.5885 21 12C21 19.4115 19.4115 21 12 21C4.5885 21 3 19.4115 3 12Z" stroke="#323232" stroke-width="2"/>
                    <path d="M15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10Z" stroke="#323232" stroke-width="2"/>
                    <path d="M6 19C6.63819 16.6928 8.27998 16 12 16C15.72 16 17.3618 16.6425 18 18.9497" stroke="#323232" stroke-width="2" stroke-linecap="round"/>
                </svg>
                <p class="text-sm flex gap-1 relative">Account</p>
                <svg class="transition-all shrink-0 origin-center" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="m4 7 4 4 4-4"></path>
                </svg>
            </div>
            <div
                v-if="isDropdownOpen"
                class="w-[150px] text-display translate-y-2 rounded-xl p-4 space-y-1 shadow-card3 absolute ltr:left-1/2 rtl:right-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2 bg-white opacity-100 scale-y-100 transition-all origin-top grid grid-cols-1 shadow-inner"
                @click.stop
            >
                <a href="/profile" class="hover:text-[#A563D8] py-1 w-full">Profile</a>
                <div class="hover:text-[#A563D8] py-1 w-full">
                    <a @click.prevent="logout" class="dropdown-item" href="#">Logout</a>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            isPopupVisible: false,
            isDropdownOpen: false,
            isLoggedIn: false,
            form: {
                email: '',
                password: '',
                remember: false,
            },
            errors: {},
            csrfToken: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            hasPasswordRequestRoute: true,
            passwordRequestRoute: 'forgot-password',
        };
    },
    mounted() {
        this.checkLoginStatus();
    },
    methods: {
        openPopup() {
            this.isPopupVisible = true;
            this.loadTurnstileScript();
        },
        closePopup() {
            this.isPopupVisible = false;
        },
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        loadTurnstileScript() {
            if (document.getElementById('turnstile-script')) return;
            const script = document.createElement('script');
            script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
            script.async = true;
            script.defer = true;
            script.id = 'turnstile-script';
            document.body.appendChild(script);
        },
        async submitForm() {
            const turnstileWidget = document.querySelector('.cf-turnstile');
            const turnstileResponse = window.turnstile.getResponse(turnstileWidget);

            this.form['cf-turnstile-response'] = turnstileResponse;
            try {
                const response = await axios.post('/login', this.form, {
                    headers: {
                        'X-CSRF-TOKEN': this.csrfToken
                    }
                });
                if (response.data.redirect) {
                    window.location.href = response.data.redirect;
                }
            } catch (error) {
                if (error.response && error.response.status === 422) {
                    this.errors = error.response.data.errors || {};
                }
            }
        },
        async logout() {
            try {
                await fetch('/logout', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                    },
                    body: JSON.stringify({})
                });
                window.location.href = '/';
                this.isLoggedIn = false;
            } catch (error) {
                console.error('Logout failed:', error);
            }
        },
        async checkLoginStatus() {
            try {
                const response = await axios.get('/check-login'); // Endpoint to check login status
                this.isLoggedIn = response.data.loggedIn; // Set login status based on response
            } catch (error) {
                console.error('Failed to check login status:', error);
            }
        },
    }
};
</script>
