<template>
    <div class="block w-full md:flex h-full relative mx-auto">
        <div class="relative h-full w-full">
            <div class="w-full h-full overflow-x-hidden">
                <div class="relative w-full">
                    <div class="text-gray-700 grid grid-cols-1 md:grid-cols-3 gap-x-2 ">
                        <div class="flex bg-white p-2 md:col-span-2 flex-wrap rounded-md content-start">
                            <label for="sentenceCounter" class="flex text-sm font-semibold mt-1 w-full">Type or paste your text</label>
                            <textarea id="sentenceCounter" rows="12" v-model="text" class="w-full border-gray-200 rounded-md mt-1" placeholder="Type or paste your text"></textarea>
                        </div>
                        <div class="mt-4 md:mt-0">
                            <div class="grid grid-cols-6 bg-white h-56 md:max-w-sm">
                                <div class="col-span-6 grid grid-cols-3 border-b-2 border-gray-200 h-18">
                                    <div class="justify-center text-center content-center border-r-2 border-gray-200">
                                        <p class="text-xl">{{ wordCount ?? 0 }}</p>
                                        <p class="text-[14px] font-semibold text-grey-600">Word Count</p>
                                    </div>
                                    <div class="justify-center text-center content-center border-r-2 border-gray-200">
                                        <p class="text-xl">{{ sentenceCount ?? 0 }}</p>
                                        <p class="text-[13px] font-semibold text-grey-600">Sentence Count</p>
                                    </div>
                                    <div class="justify-center text-center content-center">
                                        <p class="text-xl">{{ paragraphCount ?? 0 }}</p>
                                        <p class="text-[13px] font-semibold text-grey-600">Paragraph count</p>
                                    </div>
                                </div>
                                <div class="col-span-6 grid grid-cols-3 border-b-2 border-gray-200 h-18">
                                    <div class="justify-center text-center content-center border-r-2 border-gray-200">
                                        <p class="text-xl">{{ syllableCount ?? 0 }}</p>
                                        <p class="text-[14px] font-semibold text-grey-600">syllables</p>
                                    </div>
                                    <div class="justify-center text-center content-center border-r-2 border-gray-200">
                                        <p class="text-xl">{{ characterCount ?? 0 }}</p>
                                        <p class="text-[13px] font-semibold text-grey-600">Character <br>(without spaces)</p>
                                    </div>
                                    <div class="justify-center text-center content-center">
                                        <p class="text-xl">{{ characterCountWithSpaces ?? 0 }}</p>
                                        <p class="text-[13px] font-semibold text-grey-600">Characters  <br>(with spaces)</p>
                                    </div>
                                </div>
                                <div class="col-span-6 grid grid-cols-2 border-b-2 border-gray-200 h-18">
                                    <div class="justify-center text-center content-center border-r-2 border-gray-200">
                                        <p class="text-xl">{{ readingTime ?? 0 }} <span class="text-[10px] font-semibold">min</span></p>
                                        <p class="text-[14px] font-semibold text-grey-600">Reading time</p>
                                    </div>
                                    <div class="justify-center text-center content-center">
                                        <p class="text-xl" >{{ gradeLevel ?? 0 }}</p>
                                        <p class="text-[14px] font-semibold text-grey-600">Grade Level</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-6 mt-4 bg-white md:max-w-sm">
                                <div v-if="paginatedKeywordDensity.length > 0" class="p-2">
                                    <h2 class="flex text-sm font-semibold px-2 my-2 w-full">Keyword Density</h2>
                                    <table class="border-collapse table-auto w-full text-sm">
                                        <thead>
                                        <tr>
                                            <th class="border-b border-grey-100 font-semibold p-2 text-left text-xs">Keyword</th>
                                            <th class="border-b border-grey-100 font-semibold p-2 text-left text-xs">Count</th>
                                            <th class="border-b border-grey-100 font-semibold p-2 text-left text-xs">Density</th>
                                        </tr>
                                        </thead>
                                        <tbody class="bg-white dark:bg-slate-800">
                                        <tr v-for="(data, keyword) in paginatedKeywordDensity" :key="keyword">
                                            <td class="border-b border-grey-100 p-1 pl-2">{{ data.keyword }}</td>
                                            <td class="border-b border-grey-100 p-1">{{ data.count }}</td>
                                            <td class="border-b border-grey-100 p-1 pr-2">{{ data.density }}%</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div class="w-full flex flex-wrap justify-center justify-items-center content-center">
                                        <button class="p-2 bg-grey-200 hover:bg-grey-300" @click="previousPage" :disabled="currentPage === 1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24" fill="none">
                                                <path d="M14.9991 19L9.83911 14C9.56672 13.7429 9.34974 13.433 9.20142 13.0891C9.0531 12.7452 8.97656 12.3745 8.97656 12C8.97656 11.6255 9.0531 11.2548 9.20142 10.9109C9.34974 10.567 9.56672 10.2571 9.83911 10L14.9991 5" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </button>
                                        <span class="text-[10px] content-center">Page {{ currentPage }} of {{ totalPages }}</span>
                                        <button class="p-2 bg-grey-200 hover:bg-grey-300" @click="nextPage" :disabled="currentPage === totalPages">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24" fill="none">
                                                <path d="M9 5L14.15 10C14.4237 10.2563 14.6419 10.5659 14.791 10.9099C14.9402 11.2539 15.0171 11.625 15.0171 12C15.0171 12.375 14.9402 12.7458 14.791 13.0898C14.6419 13.4339 14.4237 13.7437 14.15 14L9 19" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            text: "",
            gradeLevel: null,
            readingTime: null,
            wordCount: null,
            sentenceCount: null,
            characterCount: null,
            characterCountWithSpaces: null,
            paragraphCount: null,
            syllableCount: null,
            keywordDensity: {},
            currentPage: 1,
            itemsPerPage: 5,
        };
    },
    computed: {
        paginatedKeywordDensity() {

            const sortedKeywords = Object.entries(this.keywordDensity)
                .sort(([, a], [, b]) => parseFloat(b.density) - parseFloat(a.density));

            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;

            // Slice the sorted array for pagination
            return sortedKeywords.slice(start, end).map(([keyword, data]) => ({
                keyword,
                ...data,
            }));

        },
        totalPages() {
            return Math.ceil(Object.keys(this.keywordDensity).length / this.itemsPerPage);
        },
    },
    watch: {
        text: "updateMetrics",
    },
    methods: {
        countWords(text) {
            return text.split(/\s+/).filter(word => word.length > 0).length;
        },
        countSentences(text) {
            return text.split(/[.!?]/).filter(sentence => sentence.trim().length > 0).length;
        },
        countCharactersWithoutSpaces(text) {
            return text.replace(/\s+/g, "").length;
        },
        countCharactersWithSpaces(text) {
            return text.length;
        },
        countParagraphs(text) {
            return text.split(/\n+/).filter(paragraph => paragraph.trim().length > 0).length;
        },
        countSyllables(word) {
            word = word.toLowerCase();
            const vowels = /[aeiouy]/g;
            let syllableCount = 0;

            if (word.endsWith("e")) {
                word = word.slice(0, -1);
            }

            const matches = word.match(vowels);
            syllableCount = matches ? matches.length : 0;

            if (syllableCount === 0) {
                syllableCount = 1;
            }

            return syllableCount;
        },
        countTotalSyllables(text) {
            const words = text.split(/\s+/).filter(word => word.length > 0);
            return words.reduce((total, word) => total + this.countSyllables(word), 0);
        },
        calculateReadingTime(wordCount) {
            const wordsPerMinute = 200; // Average reading speed
            return (wordCount / wordsPerMinute).toFixed(2);
        },
        calculateKeywordDensity(text) {
            const words = text.toLowerCase().split(/\s+/).filter(word => word.length > 0);
            const totalWords = words.length;
            const wordCounts = {};

            words.forEach(word => {
                wordCounts[word] = (wordCounts[word] || 0) + 1;
            });

            const keywordDensity = {};
            Object.entries(wordCounts).forEach(([word, count]) => {
                keywordDensity[word] = {
                    count,
                    density: ((count / totalWords) * 100).toFixed(1),
                };
            });

            return keywordDensity;
        },
        updateMetrics() {
            const totalWords = this.countWords(this.text);
            const totalSentences = this.countSentences(this.text);
            const totalSyllables = this.countTotalSyllables(this.text);
            const totalCharacters = this.countCharactersWithoutSpaces(this.text);
            const totalCharactersWithSpaces = this.countCharactersWithSpaces(this.text);
            const totalParagraphs = this.countParagraphs(this.text);

            const gradeLevel = (
                0.39 * (totalWords / totalSentences) +
                11.8 * (totalSyllables / totalWords) -
                15.59
            ).toFixed(2);

            const readingTime = this.calculateReadingTime(totalWords);

            this.gradeLevel = parseFloat(gradeLevel);
            this.readingTime = parseFloat(readingTime);
            this.wordCount = totalWords;
            this.sentenceCount = totalSentences;
            this.characterCount = totalCharacters;
            this.characterCountWithSpaces = totalCharactersWithSpaces;
            this.paragraphCount = totalParagraphs;
            this.syllableCount = totalSyllables;
            this.keywordDensity = this.calculateKeywordDensity(this.text);
            this.currentPage = 1; // Reset to the first page on text change
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
    },
};

</script>
